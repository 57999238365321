<template>
  <Popup
    title="데이터 조회 상세"
    :maxWidth="1000"
    closeBtnText="닫기"
    @onClickClose="$emit('onClickDbDetailPopupClose')">
    <TableView>
      <template v-slot:tbl_colgroup>
        <col style="width:200px"/>
        <col />
      </template>
      <template v-slot:tbl_body>
        <tr
          v-for="(itemData,index) in keyArray"
          :key="`itemData${index}`">
          <th>{{ itemData }}</th>
          <td>
            <span class="txt_tbl">{{ detailData[itemData] }}</span>
          </td>
        </tr>
      </template>
    </TableView>
  </Popup>
</template>

<script>
import Popup from '@lemontree-ai/lemontree-admin-common-front/components/layout/popup/Popup';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';


export default {
  name:'DbDetailPopup',
  components:{
    Popup,
    TableView,
  },
  props:{
    detailData: Object,
  },
  computed:{
    keyArray(){
      return Object.keys(this.detailData)
    }
  }
}
</script>

<style lang="scss" scoped>
.tbl_view .txt_tbl{word-break:break-all}
.tbl_view tbody td pre{line-height:18px}
.tbl_view tbody td .txt_tree{display:block}
.tbl_view tbody td .txt_tree ::v-deep div{padding-left:20px}
</style>