import { GET_REQUEST, POST_REQUEST } from '@/services/http';
import { makeQueryStringByObject } from '@lemontree-ai/lemontree-admin-common-front/utils/urlUtils';
import apiPath from '@/services/apiPath';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import router from '@/router';
import { convertIdToText } from "@lemontree-ai/lemontree-admin-common-front/utils/selectDataUtils";

export default class DbListViewModel {
  constructor() {
    this.searchDataList = [
      {
        title:'테이블명',
        type: 'InputButton',
        grid: 100,
        isFull:true,
        value:'',
      },
      {
        title:'검색조건1',
        type: 'InputInputButton',
        grid: 100,
        maxWidth:'50%',
        isFull:true,
        placeholder1:'key name',
        value1:'',
        placeholder2:'value name',
        value2:'',
      },
    ]
    this.serachParamscount = 1;
    this.boardData = {
      title:'데이터 조회',
      drawDataList: [],
      option:{
        isTotal: true,
        isSize: true
      }
    };
    this.dataList = [];
    this.searchParams = {
      page:0,
      pageSize:10,
      params:{
        // "expire_year": 2027
      }
      // direction: 'DESC'
    };
    this.paginationData = {
      totalCount:0,
      totalPage:0
    };
    this.isDbDetailPopup = false;
    this.dbDetailData = {};
  }

  init(){
    this.getDbList();
  }
  onClickRow(rowId,rowData){
    this.isDbDetailPopup = true;
    this.dbDetailData = rowData;
  }
  onClickDbDetailPopupClose(){
    this.isDbDetailPopup = false;
    this.dbDetailData = {};
  }
  onSearch(){
    this.getDbList();
  }

  searchConditionAdd(){
    this.serachParamscount += 1;
    const conditionItem = {
      title:`검색조건${this.serachParamscount}`,
      type: 'InputInputButton',
      grid: 100,
      maxWidth:'50%',
      isFull:true,
      placeholder1:'key name',
      value1:'',
      placeholder2:'value name',
      value2:'',
    }
    this.searchDataList.push(conditionItem)
  }
  searchConditionDelete(index){
    this.serachParamscount -= 1;
    this.searchDataList.splice(index,1);
  }


  getDbList(){
    if(!this.searchDataList[0].value){
      store.dispatch('commonToast/fetchToastStart', '테이블명을 입력해주세요');
      return;
    }
    this.boardData.drawDataList = [];
    this.paginationData.totalCount = 0;
    this.paginationData.totalPage = 0;
    const path = `${apiPath.DB_SEARCH_LIST}`;
    let params = {};
    for(var paramsIndex = 1; paramsIndex <= this.serachParamscount; paramsIndex++){
      if(this.searchDataList[paramsIndex].value1 && this.searchDataList[paramsIndex].value2){
        params[this.searchDataList[paramsIndex].value1] = this.searchDataList[paramsIndex].value2;
      }
    }
    const data = {
      table : this.searchDataList[0].value,
      page : this.searchParams.page,
      pageSize : this.searchParams.pageSize,
      params : params,
    }
    POST_REQUEST(path,data).then(
    (success) => {
      const resultData = success.data;
      const dataList = resultData.data;
      if(dataList.length > 0){
        const keysArray = Object.keys(dataList[0]);
        keysArray.forEach(keyName => {
          const itemCol = {
            title:keyName,
            width:'',
            value:keyName,
            isAlignLeft: true,
            isEllip: true,
            emptyValueText : '-'
          }
          this.boardData.drawDataList.push(itemCol)
        })
      }

      this.dataList = dataList;

      this.paginationData.totalCount = resultData.total;
      this.paginationData.totalPage = resultData.totalPage;
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}