<template>
  <PageWithLayout>
    <Search
      :searchDataList.sync="viewModel.searchDataList"
      :searchParams.sync="viewModel.searchParams"
      @onClickAdd="viewModel.searchConditionAdd()"
      @onClickDelete="index => viewModel.searchConditionDelete(index)"
      @onSearch="viewModel.onSearch()"/>
    <Board
      :boardData="viewModel.boardData"
      :searchParams.sync="viewModel.searchParams"
      :dataList="viewModel.dataList"
      :paginationData="viewModel.paginationData"
      @onClickRow="(rowId,rowData) => viewModel.onClickRow(rowId,rowData)"
      @onSearch="viewModel.onSearch()">
    </Board>
    <template v-slot:popup>
      <DbDetailPopup
        v-if="viewModel.isDbDetailPopup"
        :detailData="viewModel.dbDetailData"
        @onClickDbDetailPopupClose="viewModel.onClickDbDetailPopupClose()"/>
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import Search from '@lemontree-ai/lemontree-admin-common-front/components/common/search/Search';
import Board from '@lemontree-ai/lemontree-admin-common-front/components/common/board/Board';
import DbDetailPopup from '@/views/admin/db/view/popup/DbDetailPopup'
// viewModel
import DbListViewModel from '@/views/admin/db/viewModel/DbListViewModel'

export default {
  name:'DbList',
  components:{
    PageWithLayout,
    Search,
    Board,
    DbDetailPopup
  },
  beforeMount(){
    // this.viewModel.init();
  },
  data(){
    return{
      viewModel: new DbListViewModel(),
    }
  },
}
</script>
<style scoped>
.board_comm::v-deep .tbl_comm table{min-width:100%;width:auto}
.board_comm::v-deep .tbl_comm table .txt_tbl{display:block;max-width:200px}
</style>